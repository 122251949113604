import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import originalAxios from "axios";

import createAxiosInstance from "../async/axios";
import { getRequestParams } from "../async/get-fetch-params";
import { getAuthLoggedIn } from "../reselect/auth-selector";

export const getInitialState = () => ({
  data: {
    popularBetslips: {},
    recommendedBetBuilders: {},
    recommendedBetslips: {},
    recommendedEvents: {},
    searchResults: {},
    similarEvents: {},
  },
  error: {},
  loading: {},
});

const vaixCancelToken = {};

const createVaixThunk = (name, prepareParams = (params) => params) =>
  createAsyncThunk(`vaix/${name}`, async ({ cacheKey, params }, thunkAPI) => {
    try {
      const { accountId, authToken, language, lineId, originId } = getRequestParams(thunkAPI.getState());

      if (!vaixCancelToken[cacheKey]) {
        vaixCancelToken[cacheKey] = {};
      }
      if (vaixCancelToken[cacheKey][name]) {
        vaixCancelToken[cacheKey][name].cancel("Operation canceled due to new request.");
      }
      vaixCancelToken[cacheKey][name] = originalAxios.CancelToken.source();

      const axios = createAxiosInstance(thunkAPI.dispatch, { authToken, language });

      const result = await axios.get(
        `/player/${accountId ? `acc/${accountId}/` : ""}vaix?${new URLSearchParams(
          prepareParams({
            lineId,
            originId,
            vaix: name,
            ...params,
          }),
        )}`,
        {
          cancelToken: vaixCancelToken[cacheKey][name].token,
        },
      );

      return { cacheKey, data: result.data };
    } catch (err) {
      const customError = {
        cacheKey,
        message: err.response?.headers["x-information"] || `Unable to fetch ${name}`,
        name: `${name} Error`,
        status: err.response?.statusText,
      };
      throw customError;
    }
  });

export const getRecommendedEvents = createVaixThunk("RECOMMENDED_EVENTS", (params) => ({
  ...params,
  live: params.live ? true : undefined,
}));

export const getRecommendedBetslips = createVaixThunk("RECOMMENDED_BETSLIPS");

export const getPopularBetslips = createVaixThunk("POPULAR_BETSLIPS");

export const getRecommendedBetBuilders = createVaixThunk("RECOMMENDED_BET_BUILDERS");

export const getSimilarEvents = createVaixThunk("EVENTS_SIMILAR", (params) => ({
  ...params,
  eventIds: params.eventIds.join(","),
  live: params.live ? true : undefined,
}));

export const searchEvents = createVaixThunk("SEARCH", (params) => ({ ...params, live: params.live ? 1 : undefined }));

// New tracking thunk creator
const createTrackingThunk = (name) =>
  createAsyncThunk(`vaix/track/${name}`, async (params, thunkAPI) => {
    try {
      const { accountId, authToken, language, lineId, originId } = getRequestParams(thunkAPI.getState());
      const isLoggedIn = getAuthLoggedIn(thunkAPI.getState());
      if (!isLoggedIn) return;

      const axios = createAxiosInstance(thunkAPI.dispatch, { authToken, language });

      const result = await axios.post(
        `/player/${accountId ? `acc/${accountId}/` : ""}vaix/track?${new URLSearchParams({
          lineId,
          originId,
        })}`,
        params,
      );

      return result.data;
    } catch (err) {
      const customError = {
        message: err.response?.headers["x-information"] || `Unable to track ${name}`,
        name: `${name} Tracking Error`,
        status: err.response?.statusText,
      };
      throw customError;
    }
  });

// Create tracking thunks for each event type
export const trackEventClick = createTrackingThunk("EVENT_CLICK");
export const trackOutcomeClick = createTrackingThunk("OUTCOME_CLICK");
export const trackCombinationsClick = createTrackingThunk("COMBINATIONS_CLICK");

// Helper functions to format tracking data
export const createEventClickPayload = ({ eventId, location = "", page = "/home" }) => ({
  eventId,
  location,
  page,
  vaixEventType: "CLICK_EVENT",
});

export const createOutcomeClickPayload = ({ eventId, location = "", outcomeId, page = "/home" }) => ({
  eventId,
  location,
  outcomeId,
  page,
  vaixEventType: "CLICK_OUTCOME",
});

export const createCombinationsClickPayload = ({ location = "", page = "/home", position = 0, selections }) => ({
  location,
  page,
  position,
  selections: selections.map(({ eventId, outcomeId }) => ({
    eventId,
    outcomeId,
  })),
  vaixEventType: "CLICK_COMBINATIONS",
});

const vaixSlice = createSlice({
  extraReducers: (builder) => {
    const addVaixCases = (thunk, stateProp) => {
      builder
        .addCase(thunk.pending, (state, action) => {
          const { cacheKey } = action.meta.arg;
          state.error[cacheKey] = null;
          state.loading[cacheKey] = true;
        })
        .addCase(thunk.fulfilled, (state, action) => {
          const { cacheKey, data } = action.payload;
          state.error[cacheKey] = null;
          state.loading[cacheKey] = false;
          state.data[stateProp][cacheKey] = data;
        })
        .addCase(thunk.rejected, (state, action) => {
          const { cacheKey } = action.meta.arg;
          state.error[cacheKey] = action.error.message;
          state.loading[cacheKey] = false;
        });
    };

    addVaixCases(getRecommendedEvents, "recommendedEvents");
    addVaixCases(getRecommendedBetslips, "recommendedBetslips");
    addVaixCases(getPopularBetslips, "popularBetslips");
    addVaixCases(getRecommendedBetBuilders, "recommendedBetBuilders");
    addVaixCases(getSimilarEvents, "similarEvents");
    addVaixCases(searchEvents, "searchResults");
  },
  initialState: getInitialState(),
  name: "vaix",
  reducers: {
    clearCacheKey: (state, action) => {
      const { cacheKey, feature } = action.payload;
      if (state.data[feature]?.[cacheKey]) {
        delete state.data[feature][cacheKey];
        delete state.loading[cacheKey];
        delete state.error[cacheKey];
      }
    },
    clearVaixData: (state) => getInitialState(),
  },
});

export const {
  clearCacheKey,
  clearPopularBetslips,
  clearRecommendedBetBuilders,
  clearRecommendedBetslips,
  clearRecommendedEvents,
  clearSearchResults,
  clearSimilarEvents,
} = vaixSlice.actions;

export default vaixSlice.reducer;
